import flatpickr from 'flatpickr';
import { router } from 'jqueryrouter';

export default event => {
	const $part = $('.js-input-field');
	const $part1 = $('.js-input-field-1');
	const $part2 = $('.js-input-field-2');
	const $part3 = $('.js-input-field-3');
	const $part4 = $('.js-input-field-4');

	const $question_part1 = $('.js-question-field-1');
	const $question_part2 = $('.js-question-field-2');
	const $question_part3 = $('.js-question-field-3');
	const $question_part4 = $('.js-question-field-4');
	const $question_part5 = $('.js-question-field-5');
	const $question_part6 = $('.js-question-field-6');

	const $btn_part2 = $('.js-input-button-part-2');
	const $btn_part3 = $('.js-input-button-part-3');

	// Initiating Date Picker
	$('.js-input-field-5').flatpickr({
		disableMobile: true,
	});

	window.formValidationPassed;

	console.log('Form Function init');

	// $($part).prop('required', true);

	// Part 2 Button Block
	$($btn_part2).on('click', function(e) {
		e.preventDefault();

		// Get all the forms elements and their values in one step
		window.formValueForMarketoFirstName = $(
			"input[name='FirstName']",
		).val();
		window.formValueForMarketoLastName = $("input[name='LastName']").val();
		window.formValueForMarketoEmail = $("input[name='Email']").val();
		window.formValueForMarketoCompany = $("input[name='Company']").val();
		window.formValueForMarketoGdrp = $("input[name='GDRP']").val();
		window.formValueForDate = $("input[name='Date']").val();

		console.log('First Name:' + window.formValueForMarketoFirstName);
		console.log('First Last:' + window.formValueForMarketoLastName);
		console.log('Email:' + window.formValueForMarketoEmail);
		console.log('Company:' + window.formValueForMarketoCompany);
		console.log('GDRP:' + window.formValueForMarketoGdrp);
		console.log('Date:' + window.formValueForDate);

		validateForm();

		if (window.formValidationPassed == true) {
			console.log('form validation passed');
			router.set('/part3');
		} else {
			console.log('form validation not passed');
			e.preventDefault();
		}
	});

	function validateForm() {
		// Grabbing the email value
		const email = $('.js-input-field-3').val();

		// Checks if email is valid
		function validateEmail(email) {
			const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
			return re.test(String(email).toLowerCase());
		}

		checkIfInputIsEmptyForm();

		console.log(window.formFieldsFilled);

		// Checking if email is valid
		if (validateEmail(email) && window.formFieldsFilled) {
			console.log('email is valid + form validated');
			window.formValidationPassed = true;

			if (
				$('.js-message-1').hasClass(
					'c-slide-selector__message--visible',
				)
			) {
				$('.js-message-1').removeClass(
					'c-slide-selector__message--visible',
				);
			}
		} else {
			window.formValidationPassed = false;
			if (
				!$('.js-message-1').hasClass(
					'c-slide-selector__message--visible',
				)
			) {
				$('.js-message-1').addClass(
					'c-slide-selector__message--visible',
				);
			}

			console.log(
				'email is not valid or fields are missing. Form is not validated',
			);
		}
	}

	// Check if inputs are not empty
	function checkIfInputIsEmptyForm() {
		var val1 = $($part1).val();
		var val2 = $($part2).val();
		var val3 = $($part3).val();
		var val4 = $($part4).val();

		if (
			val1.length == 0 ||
			val2.length == 0 ||
			val3.length == 0 ||
			val4.length == 0
		) {
			window.formFieldsFilled = false;
			console.log('All required fields are have not been entered');
		} else {
			window.formFieldsFilled = true;
			console.log('All required fields are have been entered');
		}
	}

	$($part)
		.focus(function() {
			$(this)
				.parent()
				.removeClass('c-slide-selector__input-wrapper--not-blurred');
			$(this)
				.parent()
				.addClass('c-slide-selector__input-wrapper--blurred');
		})
		.blur(function() {
			$(this)
				.parent()
				.removeClass('c-slide-selector__input-wrapper--blurred');
			$(this)
				.parent()
				.addClass('c-slide-selector__input-wrapper--not-blurred');
		});

	function checkIfInputIsValid() {
		return false;
	}

	// PART 3
	// PART 3
	// PART 3

	var question = 'c-card-question__option';

	// Question 1
	var question1_1 = $('.js-question-1-option-1');
	var question1_2 = $('.js-question-1-option-2');

	// Question 2
	var question2_1 = $('.js-question-2-option-1');
	var question2_2 = $('.js-question-2-option-2');
	var question2_3 = $('.js-question-2-option-3');

	// Question 3
	var question3_1 = $('.js-question-3-option-1');
	var question3_2 = $('.js-question-3-option-2');

	// Question 4
	var question4_1 = $('.js-question-4-option-1');
	var question4_2 = $('.js-question-4-option-2');

	// Question 5
	var question5_1 = $('.js-question-5-option-1');
	var question5_2 = $('.js-question-5-option-2');
	var question5_3 = $('.js-question-5-option-3');
	var question5_4 = $('.js-question-5-option-4');
	var question5_5 = $('.js-question-5-option-5');
	var question5_6 = $('.js-question-5-option-6');
	var question5_7 = $('.js-question-5-option-7');

	// Question 6
	var question6_1 = $('.js-question-6-option-1');
	var question6_2 = $('.js-question-6-option-2');
	var question6_3 = $('.js-question-6-option-3');

	// Question active class
	var questionActive = 'c-card-question__option--active';

	window.questionAnswer1_answered = false;
	window.questionAnswer1_1 = false;
	window.questionAnswer1_2 = false;

	window.questionAnswer2_answered = false;
	window.questionAnswer2_1 = false;
	window.questionAnswer2_2 = false;
	window.questionAnswer2_3 = false;

	window.questionAnswer3_answered = false;
	window.questionAnswer3_1 = false;
	window.questionAnswer3_2 = false;

	window.questionAnswer4_answered = false;
	window.questionAnswer4_1 = false;
	window.questionAnswer4_2 = false;

	window.questionAnswer5_answered = false;
	window.questionAnswer5_1 = false;
	window.questionAnswer5_2 = false;
	window.questionAnswer5_3 = false;
	window.questionAnswer5_4 = false;
	window.questionAnswer5_5 = false;
	window.questionAnswer5_6 = false;
	window.questionAnswer5_7 = false;

	window.questionAnswer6_answered = false;
	window.questionAnswer6_1 = false;
	window.questionAnswer6_2 = false;
	window.questionAnswer6_3 = false;

	$(question1_1).on('click', function() {
		window.questionAnswer1_1 = true;
		window.questionAnswer1_2 = false;
		window.questionAnswer1_answered = true;
		$(question1_1).addClass(questionActive);
		$(question1_2).removeClass(questionActive);
		console.log(window.questionAnswer1_1);

		$('.js-radio-1-a').prop('checked', true);
		$('.js-radio-1-b').prop('checked', false);
	});

	$(question1_2).on('click', function() {
		window.questionAnswer1_2 = true;
		window.questionAnswer1_1 = false;
		window.questionAnswer1_answered = true;
		$(question1_2).addClass(questionActive);
		$(question1_1).removeClass(questionActive);
		console.log(window.questionAnswer1_2);

		$('.js-radio-1-a').prop('checked', false);
		$('.js-radio-1-b').prop('checked', true);
	});

	// Question 2 - 1
	$(question2_1).on('click', function() {
		window.questionAnswer2_1 = true;
		window.questionAnswer2_2 = false;
		window.questionAnswer2_3 = false;
		window.questionAnswer2_answered = true;
		$(question2_1).addClass(questionActive);
		$(question2_2).removeClass(questionActive);
		$(question2_3).removeClass(questionActive);
		console.log(window.questionAnswer2_1);

		$('.js-select-2').val('Pharmacy');
	});

	// Question 2 - 2
	$(question2_2).on('click', function() {
		window.questionAnswer2_1 = false;
		window.questionAnswer2_2 = true;
		window.questionAnswer2_3 = false;
		window.questionAnswer2_answered = true;
		$(question2_1).removeClass(questionActive);
		$(question2_2).addClass(questionActive);
		$(question2_3).removeClass(questionActive);
		console.log(window.questionAnswer2_2);

		$('.js-select-2').val('Medical');
	});

	// Question 2 - 3
	$(question2_3).on('click', function() {
		window.questionAnswer2_1 = false;
		window.questionAnswer2_2 = false;
		window.questionAnswer2_3 = true;
		window.questionAnswer2_answered = true;
		$(question2_1).removeClass(questionActive);
		$(question2_2).removeClass(questionActive);
		$(question2_3).addClass(questionActive);
		console.log(window.questionAnswer2_3);

		$('.js-select-2').val('Both');
	});

	// Question 3 - 1
	$(question3_1).on('click', function() {
		window.questionAnswer3_1 = true;
		window.questionAnswer3_2 = false;
		window.questionAnswer3_answered = true;
		$(question3_1).addClass(questionActive);
		$(question3_2).removeClass(questionActive);
		console.log(window.questionAnswer3_1);

		$('.js-radio-2-a').prop('checked', true);
		$('.js-radio-2-b').prop('checked', false);
	});

	// Question 3 - 2
	$(question3_2).on('click', function() {
		window.questionAnswer3_2 = true;
		window.questionAnswer3_1 = false;
		window.questionAnswer3_answered = true;
		$(question3_2).addClass(questionActive);
		$(question3_1).removeClass(questionActive);
		console.log(window.questionAnswer3_2);

		$('.js-radio-2-a').prop('checked', false);
		$('.js-radio-2-b').prop('checked', true);
	});

	// Question 4 - 1
	$(question4_1).on('click', function() {
		window.questionAnswer4_1 = true;
		window.questionAnswer4_2 = false;
		window.questionAnswer4_answered = true;
		$(question4_1).addClass(questionActive);
		$(question4_2).removeClass(questionActive);
		console.log(window.questionAnswer4_1);

		$('.js-radio-3-a').prop('checked', true);
		$('.js-radio-3-b').prop('checked', false);
	});

	// Question 4 - 2
	$(question4_2).on('click', function() {
		window.questionAnswer4_2 = true;
		window.questionAnswer4_1 = false;
		window.questionAnswer4_answered = true;
		$(question4_2).addClass(questionActive);
		$(question4_1).removeClass(questionActive);
		console.log(window.questionAnswer4_2);

		$('.js-radio-3-a').prop('checked', false);
		$('.js-radio-3-b').prop('checked', true);
	});

	// Question 5 - 1
	$(question5_1).on('click', function() {
		window.questionAnswer5_1 = true;
		window.questionAnswer5_2 = false;
		window.questionAnswer5_3 = false;
		window.questionAnswer5_4 = false;
		window.questionAnswer5_5 = false;
		window.questionAnswer5_6 = false;
		window.questionAnswer5_7 = false;
		window.questionAnswer5_answered = true;
		$(question5_1).addClass(questionActive);
		$(question5_2).removeClass(questionActive);
		$(question5_3).removeClass(questionActive);
		$(question5_4).removeClass(questionActive);
		$(question5_5).removeClass(questionActive);
		$(question5_6).removeClass(questionActive);
		$(question5_7).removeClass(questionActive);
		console.log(window.questionAnswer5_1);

		$('.js-select-1').val('Oncology');
	});

	// Question 5 - 2
	$(question5_2).on('click', function() {
		window.questionAnswer5_1 = false;
		window.questionAnswer5_2 = true;
		window.questionAnswer5_3 = false;
		window.questionAnswer5_4 = false;
		window.questionAnswer5_5 = false;
		window.questionAnswer5_6 = false;
		window.questionAnswer5_7 = false;
		window.questionAnswer5_answered = true;
		$(question5_1).removeClass(questionActive);
		$(question5_2).addClass(questionActive);
		$(question5_3).removeClass(questionActive);
		$(question5_4).removeClass(questionActive);
		$(question5_5).removeClass(questionActive);
		$(question5_6).removeClass(questionActive);
		$(question5_7).removeClass(questionActive);
		console.log(window.questionAnswer5_1);

		$('.js-select-1').val('Rheumatology');
	});

	// Question 5 - 3
	$(question5_3).on('click', function() {
		window.questionAnswer5_1 = false;
		window.questionAnswer5_2 = false;
		window.questionAnswer5_3 = true;
		window.questionAnswer5_4 = false;
		window.questionAnswer5_5 = false;
		window.questionAnswer5_6 = false;
		window.questionAnswer5_7 = false;
		window.questionAnswer5_answered = true;
		$(question5_1).removeClass(questionActive);
		$(question5_2).removeClass(questionActive);
		$(question5_3).addClass(questionActive);
		$(question5_4).removeClass(questionActive);
		$(question5_5).removeClass(questionActive);
		$(question5_6).removeClass(questionActive);
		$(question5_7).removeClass(questionActive);
		console.log(window.questionAnswer5_3);

		$('.js-select-1').val('Neurology');
	});

	// Question 5 - 4
	$(question5_4).on('click', function() {
		window.questionAnswer5_1 = false;
		window.questionAnswer5_2 = false;
		window.questionAnswer5_3 = false;
		window.questionAnswer5_4 = true;
		window.questionAnswer5_5 = false;
		window.questionAnswer5_6 = false;
		window.questionAnswer5_7 = false;
		window.questionAnswer5_answered = true;
		$(question5_1).removeClass(questionActive);
		$(question5_2).removeClass(questionActive);
		$(question5_3).removeClass(questionActive);
		$(question5_4).addClass(questionActive);
		$(question5_5).removeClass(questionActive);
		$(question5_6).removeClass(questionActive);
		$(question5_7).removeClass(questionActive);
		console.log(window.questionAnswer5_4);

		$('.js-select-1').val('Gastroenterology');
	});

	// Question 5 - 5
	$(question5_5).on('click', function() {
		window.questionAnswer5_1 = false;
		window.questionAnswer5_2 = false;
		window.questionAnswer5_3 = false;
		window.questionAnswer5_4 = false;
		window.questionAnswer5_5 = true;
		window.questionAnswer5_6 = false;
		window.questionAnswer5_7 = false;
		window.questionAnswer5_answered = true;
		$(question5_1).removeClass(questionActive);
		$(question5_2).removeClass(questionActive);
		$(question5_3).removeClass(questionActive);
		$(question5_4).removeClass(questionActive);
		$(question5_5).addClass(questionActive);
		$(question5_6).removeClass(questionActive);
		$(question5_7).removeClass(questionActive);
		console.log(window.questionAnswer5_5);

		$('.js-select-1').val('Rare Disease');
	});

	// Question 5 - 6
	$(question5_6).on('click', function() {
		window.questionAnswer5_1 = false;
		window.questionAnswer5_2 = false;
		window.questionAnswer5_3 = false;
		window.questionAnswer5_4 = false;
		window.questionAnswer5_5 = false;
		window.questionAnswer5_6 = true;
		window.questionAnswer5_7 = false;
		window.questionAnswer5_answered = true;
		$(question5_1).removeClass(questionActive);
		$(question5_2).removeClass(questionActive);
		$(question5_3).removeClass(questionActive);
		$(question5_4).removeClass(questionActive);
		$(question5_5).removeClass(questionActive);
		$(question5_6).addClass(questionActive);
		$(question5_7).removeClass(questionActive);
		console.log(window.questionAnswer5_6);

		$('.js-select-1').val('Immunology');
	});

	// Question 5 - 7
	$(question5_7).on('click', function() {
		window.questionAnswer5_1 = false;
		window.questionAnswer5_2 = false;
		window.questionAnswer5_3 = false;
		window.questionAnswer5_4 = false;
		window.questionAnswer5_5 = false;
		window.questionAnswer5_6 = false;
		window.questionAnswer5_7 = true;
		window.questionAnswer5_answered = true;
		$(question5_1).removeClass(questionActive);
		$(question5_2).removeClass(questionActive);
		$(question5_3).removeClass(questionActive);
		$(question5_4).removeClass(questionActive);
		$(question5_5).removeClass(questionActive);
		$(question5_6).removeClass(questionActive);
		$(question5_7).addClass(questionActive);

		$('.js-select-1').val('Other');

		$('.js-question-5-option-wrapper').removeClass(
			'c-card-question__options-wrapper--hidden',
		);

		console.log(window.questionAnswer5_7);
	});

	// Question 6 - 1
	$(question6_1).on('click', function() {
		window.questionAnswer6_1 = true;
		window.questionAnswer6_2 = false;
		window.questionAnswer6_3 = false;
		window.questionAnswer6_answered = true;
		$(question6_1).addClass(questionActive);
		$(question6_2).removeClass(questionActive);
		$(question6_3).removeClass(questionActive);
		console.log(window.questionAnswer6_1);

		$('.js-radio-4-a').prop('checked', true);
		$('.js-radio-4-b').prop('checked', false);
		$('.js-radio-4-c').prop('checked', false);

		$('.js-question-6-option-wrapper').removeClass(
			'c-card-question__options-wrapper--hidden',
		);
	});

	// Question 6 - 2
	$(question6_2).on('click', function() {
		window.questionAnswer6_3 = false;
		window.questionAnswer6_2 = true;
		window.questionAnswer6_1 = false;
		window.questionAnswer6_answered = true;
		$(question6_2).addClass(questionActive);
		$(question6_1).removeClass(questionActive);
		$(question6_3).removeClass(questionActive);
		console.log(window.questionAnswer6_2);

		$('.js-radio-4-a').prop('checked', false);
		$('.js-radio-4-b').prop('checked', true);
		$('.js-radio-4-c').prop('checked', false);

		if (
			!$('js-question-6-option-wrapper').hasClass(
				'c-card-question__options-wrapper--hidden',
			)
		) {
			$('.js-question-6-option-wrapper').addClass(
				'c-card-question__options-wrapper--hidden',
			);
		}
	});

	// Question 6 - 3
	$(question6_3).on('click', function() {
		window.questionAnswer6_3 = true;
		window.questionAnswer6_2 = false;
		window.questionAnswer6_1 = false;
		window.questionAnswer6_answered = true;
		$(question6_3).addClass(questionActive);
		$(question6_2).removeClass(questionActive);
		$(question6_1).removeClass(questionActive);
		console.log(window.questionAnswer6_3);

		$('.js-radio-4-a').prop('checked', false);
		$('.js-radio-4-b').prop('checked', false);
		$('.js-radio-4-c').prop('checked', true);

		if (
			!$('js-question-6-option-wrapper').hasClass(
				'c-card-question__options-wrapper--hidden',
			)
		) {
			$('.js-question-6-option-wrapper').addClass(
				'c-card-question__options-wrapper--hidden',
			);
		}
	});

	// Check if inputs are not empty
	function checkIfInputIsEmptyQuestions() {
		window.valSub1 = $('.js-input-field-7').val();
		window.valSub2 = $('.js-input-field-5').val();

		window.checkForSubQuestionA = false;
		window.checkForSubQuestionB = false;

		window.SubQuestionAanswered = false;
		window.SubQuestionBanswered = false;

		window.MainQuestionsAanswered = false;

		if (window.questionAnswer5_7) {
			window.checkForSubQuestionA = true;
			window.SubQuestionAanswered = false;
		} else {
			window.checkForSubQuestionA = false;
			window.SubQuestionAanswered = true;
		}

		if (window.questionAnswer6_1) {
			window.checkForSubQuestionB = true;
			window.SubQuestionBanswered = false;
		} else {
			window.checkForSubQuestionB = false;
			window.SubQuestionBanswered = true;
		}

		if (
			window.questionAnswer1_answered === true &&
			window.questionAnswer2_answered === true &&
			window.questionAnswer3_answered === true &&
			window.questionAnswer4_answered === true &&
			window.questionAnswer5_answered === true &&
			window.questionAnswer6_answered === true
		) {
			window.MainQuestionsAnswered = true;
		}

		console.log(window.valSub2 + 'window.valSub2');

		if (window.checkForSubQuestionA && !window.valSub1 == 0) {
			console.log('sub question 1 answered');
			window.SubQuestionAanswered = true;
		}
		if (window.checkForSubQuestionB && !window.valSub2 == 0) {
			console.log('sub question 2 answered');
			window.SubQuestionBanswered = true;
		}

		if (
			window.MainQuestionsAnswered &&
			window.SubQuestionAanswered &&
			window.SubQuestionBanswered
		) {
			window.formPart3AllQuestionsFilled = true;
		}
	}

	// Part 2 Button Block
	$($btn_part3).on('click', function(e) {
		console.log($('.js-input-field-5').val());

		checkIfInputIsEmptyQuestions();

		console.log('Checking validation for questions');

		// Checking if email is valid
		if (window.formPart3AllQuestionsFilled) {
			// e.preventDefault();
			console.log('all questions filled out');
			window.formPart3ValidationPassed = true;

			if (
				$('.js-message-2').hasClass(
					'c-slide-selector__message--visible',
				)
			) {
				$('.js-message-2').removeClass(
					'c-slide-selector__message--visible',
				);
			}

			$('.js-input-button-part-3').addClass('c-btn--loading');

			// Ready to create the slides

			console.log(
				'Form is validated. We are now going to create the slides',
			);
		} else {
			e.preventDefault();
			window.formPart3ValidationPassed = false;
			if (
				!$('.js-message-2').hasClass(
					'c-slide-selector__message--visible',
				)
			) {
				$('.js-message-2').addClass(
					'c-slide-selector__message--visible',
				);
			}

			console.log('Some fields are missing. Form is not validated');
		}
	});

	// CODE FOR SLIDE CREATION HERE
	// CODE FOR SLIDE CREATION HERE
	// CODE FOR SLIDE CREATION HERE
	function createSlides(mktoForm) {
		console.log('Creating the slides!');

		if (window.formPart3ValidationPassed) {
			window.urlBase = 'commercialization.mckesson.com/slides/?';

			var urlQuestionOne;
			var urlQuestionTwo;
			var urlQuestionThree;
			var urlQuestionFour;

			// Function to dynamically create the redirect URL
			if (window.questionAnswer1_1) {
				urlQuestionOne = 'a=f&';
				window.urlBase = window.urlBase.concat(urlQuestionOne);
			} else {
				urlQuestionOne = 'a=t&';
				window.urlBase = window.urlBase.concat(urlQuestionOne);
			}

			// Function to dynamically create the redirect URL
			if (window.questionAnswer2_1) {
				urlQuestionTwo = 'b=t&';
				window.urlBase = window.urlBase.concat(urlQuestionTwo);
			} else {
				urlQuestionTwo = 'b=t&';
				window.urlBase = window.urlBase.concat(urlQuestionTwo);
			}

			// Function to dynamically create the redirect URL
			if (window.questionAnswer3_1) {
				urlQuestionThree = 'c=t&';
				window.urlBase = window.urlBase.concat(urlQuestionThree);
			} else {
				urlQuestionThree = 'c=f&';
				window.urlBase = window.urlBase.concat(urlQuestionThree);
			}

			// Function to dynamically create the redirect URL
			if (window.questionAnswer4_1) {
				urlQuestionFour = 'd=t&';
				window.urlBase = window.urlBase.concat(urlQuestionFour);
			} else {
				urlQuestionFour = 'd=f&';
				window.urlBase = window.urlBase.concat(urlQuestionFour);
			}

			submit(window.urlBase, mktoForm);
		}
	}

	// Making a global function
	window.createSlides = createSlides;

	// Creating an entry in the client post type that includes the name of the client and the client URL.
	function submit(urlBase, mktoForm) {
		// Generating a unique ID to ensure each URL is unique
		function uuidv4() {
			return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(
				/[xy]/g,
				function(c) {
					var r = (Math.random() * 16) | 0,
						v = c == 'x' ? r : (r & 0x3) | 0x8;
					return v.toString(16);
				},
			);
		}

		window.UrlToBeCreated = uuidv4();

		$.post('/create-client/', {
			client_name: window.UrlToBeCreated,
			client_url: urlBase,
		}).done(function(data) {
			console.log('URL Generated!');
			console.log(data);

			$('.js-select-1').val('Other');

			// // Populating the hidden Marketo form URL field
			// var myForm = window.MktoForms2.allForms()[0];

			window.currentURL =
				'https://commercialization.mckesson.com/client/';
			window.urlToPassToMarketo =
				window.currentURL + window.UrlToBeCreated;

			console.log(
				"URL we're passing into the Marketo program URL: " +
					window.urlToPassToMarketo,
			);

			console.log('Adding personalized URL to the form');
			mktoForm.addHiddenFields({
				//These are the values which will be submitted to Marketo
				programURL1: window.urlToPassToMarketo,
			});

			console.log('personalized URL has been added to the form');

			console.log('Invoking Marketo form submit');
			mktoForm.submit();

			// window.location.replace(window.UrlToBeCreated);
			// // show overlay
			// $popup
			// 	.find('.js-ajax-popup__msg')
			// 	.text(data.error || `${data.msg}:  ${data.url}`)
			// 	.parent()
			// 	.show();
		});
	}
};
