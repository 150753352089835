/*
 * hamburger
 *
 * runs the functionality of clicking the hamburger icon in the top nav
 */

var selector = '.js-hamburger';
var activeClass = 'is-active';
var bodyActiveClass = 'h-scroll-lock nav-active';
var headerActiveClass = 'l-header--color-menu';
var headerActiveClass2 = 'l-header--hamburger-active';
var navMenu = '.js-slide-out';
var navMenuActiveClass = 'c-slide-out--active';

export function hamburger() {
	$(selector).click(function(e) {
		// e.preventDefault();
		$(selector).toggleClass(activeClass);
		$('.js-body').toggleClass(bodyActiveClass);
		$('.js-header').toggleClass(headerActiveClass);
		$('.js-header').toggleClass(headerActiveClass2);
		$(navMenu).toggleClass(navMenuActiveClass);
	});
}

export function hamburgerClose() {
	$(selector).removeClass(activeClass);
	$('.js-body').removeClass(bodyActiveClass);
	$('.js-header').removeClass(headerActiveClass);
	$('.js-header').removeClass(headerActiveClass2);
	$(navMenu).removeClass(navMenuActiveClass);
}
