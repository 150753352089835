function showTabsInfo() {
	const componentClass = 'c-tabs-info';

	$(`.${componentClass}__head-list-item`).on('click', function() {
		const activeItemIndex = $(this).data('head-index'),
			headListWrapper = $(this)
				.parent()
				.parent(),
			panelListWrapper = headListWrapper.siblings(
				`.${componentClass}__panel-items`,
			),
			headListActiveItemLeftPos = $(this).outerWidth() * activeItemIndex;

		$(
			headListWrapper.children(
				`.${componentClass}__head-list-decoration`,
			)[0],
		).css('transform', `translateX(${headListActiveItemLeftPos}px)`);

		headListWrapper
			.find(`.${componentClass}__head-list-item--active`)
			.removeClass(`${componentClass}__head-list-item--active`);

		panelListWrapper
			.find(`.${componentClass}__panel-item--active`)
			.removeClass(`${componentClass}__panel-item--active`);

		panelListWrapper
			.find(`[data-panel-index=${activeItemIndex}]`)
			.addClass(`${componentClass}__panel-item--active`);

		if (!$(this).hasClass(`${componentClass}__head-list-item--active`)) {
			$(this).addClass(`${componentClass}__head-list-item--active`);
		}

		if (activeItemIndex === 0) {
			panelListWrapper.css('border-radius', '0 6px 6px 6px');
		} else if (
			activeItemIndex ===
			headListWrapper
				.find(`.${componentClass}__head-list-item:last-child`)
				.data('head-index')
		) {
			panelListWrapper.css('border-radius', '6px 0 6px 6px');
		} else {
			panelListWrapper.css('border-radius', '6px');
		}
	});
}

export default showTabsInfo;
