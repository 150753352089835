import { route, router } from 'jqueryrouter';

export default () => {
	// Code for showing / hiding elements here

	$('body').addClass('slide-selector-page-active');
	$('.c-slide-selector__part--1').addClass('active');

	route('/', ({ route }) => {
		$('section').removeClass('active');
		$(`section[data-route="${route}"]`).addClass('active');
		$('body').addClass('route-1');
		$('html, body').scrollTop(0);
		$('body').removeClass('route-2');
		$('body').removeClass('route-3');
		console.log('Route Part 1 Invoked');
	});

	route('/part2', ({ route }) => {
		$('section').removeClass('active');
		$('body').addClass('route-2');
		$('body').removeClass('route-3');
		$('body').removeClass('route-1');

		$('html, body').scrollTop(0);
		$('.c-slide-selector').scrollTop(0);
		$('.c-slide-selector__part--2').scrollTop(0);
		$('.c-slide-selector').attr('style', 'overflow: auto !important');
		setTimeout(function() {
			$('html, body').scrollTop(0);
			$('.c-slide-selector').scrollTop(0);
			$('.c-slide-selector__part--2').scrollTop(0);
			$(`section[data-route="${route}"]`).addClass('active');
		}, 900);
		// $(`section[data-route="${route}"]`).addClass('active');
		// $($part1).addClass('hidden');
		console.log('Route Part 2 Invoked');
	});
	route('/part3', ({ route }) => {
		$('body').addClass('route-3');
		$('body').removeClass('route-1');
		$('body').removeClass('route-2');

		$('html, body').scrollTop(0);
		$('.c-slide-selector').scrollTop(0);
		$('.c-slide-selector__part--3').scrollTop(0);
		$('.c-slide-selector__part-overflow-wrapper').scrollTop(0);
		$('.c-slide-selector__part--3').attr(
			'style',
			'overflow: none !important',
		);
		setTimeout(function() {
			$('.c-slide-selector__part--3').scrollTop(0);
			$('html, body').scrollTop(0);
			$('.c-slide-selector__part-overflow-wrapper').scrollTop(0);
			$('.c-slide-selector__part--3').attr(
				'style',
				'overflow: auto !important',
			);
		}, 900);

		$('section').removeClass('active');
		$(`section[data-route="${route}"]`).addClass('active');
		console.log('Route Part 3 Invoked');
	});

	$('button').click(function() {
		router.set($(this).data('route'));
	});

	$('.button-part-1').click(function() {
		router.set($(this).data('route'));
	});
};
