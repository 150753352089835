function showAccordion() {
	const componentClass = 'c-accordion';

	$(`.${componentClass} .js-accordion`).click(function(e) {
		e.preventDefault();
		if (
			$(this)
				.parent()
				.parent()
				.hasClass('is-active')
		) {
			$(this)
				.parent()
				.siblings(`.${componentClass}__main-info`)
				.children(`.${componentClass}__content`)
				.slideUp('fast');
			$(this)
				.closest(`.${componentClass}__group`)
				.removeClass('is-active');
		} else {
			$(this)
				.closest(`.${componentClass}`)
				.find(`.${componentClass}__content`)
				.slideUp('fast');
			$(this)
				.closest(`.${componentClass}`)
				.children(`.${componentClass}__group`)
				.removeClass('is-active');

			$(this)
				.closest(`.${componentClass}__group`)
				.addClass('is-active');
			$(this)
				.parent()
				.siblings(`.${componentClass}__main-info`)
				.children(`.${componentClass}__content`)
				.slideDown('fast');
		}
	});

	$(`.${componentClass}__title`).on('click', function() {
		$(this)
			.parent()
			.siblings(`.${componentClass}__track`)
			.find('.js-accordion')
			.trigger('click');
	});
}

export default showAccordion;
